import {Component, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppService} from '@services/app.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subject, takeUntil} from 'rxjs';
import Swal from 'sweetalert2';
import {ProcessRegistration} from "@/model/processRegistration/processRegistration";
import {ActivityByProcessor} from "@/model/activityDetails/activityByProcessor";
import {DailyActivityDetails} from "@/model/dailyActivity/dailyActivityDetails";
import {DatePipe} from "@angular/common";
import {ProductDetails} from "@/model/productDetails/productDetails";
import {Router} from "@angular/router";

@Component({
    selector: 'app-final-activity-screen',
    templateUrl: './final-activity-screen.component.html',
    styleUrls: ['./final-activity-screen.component.scss']
})
export class FinalActivityScreenComponent implements OnInit {
    userType: string;
    processorDetails: ProcessRegistration[] = [];
    activityByProcessor: ActivityByProcessor[] = [];
    showActivity: ActivityByProcessor[] = [];
    processorId: string = "";
    activityByDate: DailyActivityDetails[] = [];
    allDailyActivity: DailyActivityDetails[] = [];
    userId: string
    activeId: string;
    activeName: string;
    activityId: string;
    isUpdate = false;
    existBatchCodeList: string[] = [];
    currentDate: any;
    productByProcessor: ProductDetails[] = [];
    productId: string = "";
    finalActivity: boolean = false;
    labelCountInput: boolean = false;
    generateButtonEnable: boolean = false;
    labelCount: number;

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private renderer: Renderer2, private datePipe: DatePipe,
        private toastr: ToastrService, private router: Router,
        private appService: AppService,
        private spinner: NgxSpinnerService
    ) {
        const currentDate = new Date();
        this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-ddThh:mm") ?? "";
    }

    dailyActivity = new UntypedFormGroup({
        pt_processor_id: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_Product_id: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activity_id: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activityname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activitydurartion: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activitydailystarttime: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activitydesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activityshortdesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activity_id_next: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activity_id_previous: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activity_secretcode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activitycreatedby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activitycreateddate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activitymodifiedby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activitymodifieddate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_addactivity_id: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activitydailyendtime: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pt_activitybatchcode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        durationType: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
    })

    dailyActivityError = {
        pt_processor_id: '',
        pt_product_id: '',
        pt_activityname: '',
        pt_activitydurartion: '',
        pt_activitydailystarttime: '',
        pt_activitydailyendtime: '',
        pt_activitydesc: '',
        pt_activityshortdesc: '',
        pt_activitybatchcode: ''
    }

    ngOnInit() {
        this.userType = localStorage.getItem('userType')
        this.getProcessorDetails();
        this.getSecretCode();
        this.dailyActivity.controls['durationType'].setValue('Hours');
        this.dailyActivity.controls['pt_activitydailyendtime'].disable();
    }

    getSecretCode() {
        this.appService.getSecretCodeForDailyActivity()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log(data);
                this.dailyActivity.controls['pt_activity_secretcode'].setValue(data.secretCode);
            }, (err: any) => {
                console.log("something went wrong :", err);
            })
    }

    getProcessorDetails() {
        if (this.userType == "Super Admin") {
            this.spinner.show();
            const createdById = localStorage.getItem('userId');
            this.appService.getProcessorRegistrationCreatedBy(createdById)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    this.spinner.hide();
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                    }
                    this.processorDetails = activeValues;
                }, (err: any) => {
                    console.log("something wrong :", err)
                    this.spinner.hide();
                })
        } else if (this.userType == "Processor") {
            this.spinner.show();
            const processorId = localStorage.getItem('userId');
            this.appService.getProcessorDetailsByProcessorId(processorId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    this.spinner.hide();
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                    }
                    this.processorDetails = activeValues;
                    this.dailyActivity.controls['pt_processor_id'].setValue(this.processorDetails[0].pt_processor_id)
                    this.processorId = this.processorDetails[0].pt_processor_id;
                    this.getProductUsingCB();
                }, (err: any) => {
                    console.log("something wrong :", err)
                    this.spinner.hide();
                })
        }
    }

    getAllDailyActivityUsingCbAndProduct(productId) {
        this.spinner.show()
        this.appService.getDailyActivityDetailsUsingCBAndProductId(localStorage.getItem('userId'), productId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("All daily data :", data)
                this.allDailyActivity = data;
                this.existBatchCodeList = this.allDailyActivity.map(item => item.pt_activitybatchcode).filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });
                console.log("All daily data batch code :", this.existBatchCodeList);
            }, (err: any) => {
                console.log("Something wrong :", err)
                this.toastr.error("Something went wrong, Please try again later")
            })
    }

    selectProcessor(event: any) {
        this.activityByProcessor = [];
        const processorId = event.target.value;
        this.processorId = processorId
        if (this.processorId != "") {
            this.getProductUsingCB();
        }
    }

    selectProduct(event: any) {
        const productId = event.target.value;
        this.productId = productId
        if (this.productId != "") {
            this.getAllDailyActivityUsingCbAndProduct(this.productId);
            this.getActivityDetailsByProcessorAndProduct();
            this.initialCurrentDateDataShow();
        }
    }

    getProductUsingCB() {
        this.spinner.show();
        this.appService.getProductUsingCB(this.processorId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Activity Details :", data)
                this.spinner.hide();
                this.productByProcessor = data;
            }, (err: any) => {
                console.log("something went wrong :", err)
                this.spinner.hide();
                this.toastr.error("Something went wrong, Please try again later");
            })
    }

    getActivityDetailsByProcessorAndProduct() {
        this.showActivity = [];
        this.spinner.show();
        this.appService.getActivityDetails(this.processorId, this.productId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Activity Details :", data)
                this.spinner.hide();
                this.activityByProcessor = data;
            }, (err: any) => {
                console.log("something went wrong :", err)
                this.spinner.hide();
            })
    }

    batchCodeCheck(event: any) {
        const value = event.target.value;
        const dailyActivityList = this.allDailyActivity.filter(item => item.pt_activitybatchcode == value);
        if (dailyActivityList.length == this.activityByProcessor.length) {
            this.dailyActivityError.pt_activitybatchcode = "Batch code already exist";
        } else {
            this.dailyActivityError.pt_activitybatchcode = "";
            if (dailyActivityList.length == 0) {
                this.showActivity = this.activityByProcessor.filter(item => item.pt_activity_id == item.pt_activity_prev_activity_id);
                this.dailyActivity.controls['pt_activityname'].setValue(this.showActivity[0].pt_activity_id)
                this.userId = this.processorId;
                this.activeId = this.showActivity[0].pt_activity_id;
                this.activeName = this.showActivity[0].pt_activityname;
                this.dailyActivity.controls['pt_activity_id_previous'].setValue(this.showActivity[0].pt_activity_prev_activity_id);
                this.dailyActivity.controls['pt_activity_id_next'].setValue(this.showActivity[0].pt_activity_next_activity_id);
            } else {
                const nextId = dailyActivityList[dailyActivityList.length - 1].pt_activity_id_next;
                this.showActivity = this.activityByProcessor.filter(item => item.pt_activity_id == nextId);
                this.dailyActivity.controls['pt_activityname'].setValue(this.showActivity[0].pt_activity_id)
                this.userId = this.processorId;
                this.activeId = this.showActivity[0].pt_activity_id;
                this.activeName = this.showActivity[0].pt_activityname;
                this.dailyActivity.controls['pt_activity_id_previous'].setValue(this.showActivity[0].pt_activity_prev_activity_id);
                this.dailyActivity.controls['pt_activity_id_next'].setValue(this.showActivity[0].pt_activity_next_activity_id);
            }
        }
    }

    dateBasedData(event: any) {
        const startDate = document.getElementById('start-date') as HTMLInputElement;
        const endDate = document.getElementById('end-date') as HTMLInputElement;
        console.log(this.dailyActivity.get('pt_processor_id').value)

        if (startDate.value != "" && endDate.value != "" && this.dailyActivity.get('pt_processor_id').value != "" && this.dailyActivity.get('pt_Product_id').value) {
            this.activityByDate = [];
            const startDateValue = startDate.value + "T00:00";
            const endDateValue = endDate.value + "T23:59";
            this.spinner.show();
            this.appService.getAllActivityByDate(startDateValue, endDateValue)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("activity data :", data)
                    this.spinner.hide();
                    let local: DailyActivityDetails[] = [];
                    for (let date of data) {
                        if (this.processorId == date.pt_activitycreatedby && this.productId == date.pt_Product_id) {
                            local.push(date);
                        }
                    }
                    this.activityByDate = local;
                }, (err: any) => {
                    console.log("Something went wrong :", err)
                    this.spinner.hide();
                })
        } else {
            this.initialCurrentDateDataShow();
        }
    }

    initialCurrentDateDataShow() {
        this.activityByDate = [];
        const currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        const startDateValue = currentDate + "T00:00";
        const endDateValue = currentDate + "T23:59";
        this.appService.getAllActivityByDate(startDateValue, endDateValue)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("activity data :", data)
                this.spinner.hide();
                let local: DailyActivityDetails[] = [];
                for (let date of data) {
                    if (this.processorId == date.pt_activitycreatedby) {
                        local.push(date);
                    }
                }
                this.activityByDate = local;
            }, (err: any) => {
                console.log("Something went wrong :", err)
                this.spinner.hide();
            })
        // this.getActivityDetailsByProcessorAndProduct();
    }

    formatDateTime(dateTime: Date): string {
        const year = dateTime.getFullYear();
        const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
        const day = dateTime.getDate().toString().padStart(2, '0');
        const hours = dateTime.getHours().toString().padStart(2, '0');
        const minutes = dateTime.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowRight", "ArrowLeft"];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    enableGenerateButton(event) {
        if (event.target.value != '') {
            this.generateButtonEnable = true;
            this.labelCount = event.target.value;
        } else {
            this.generateButtonEnable = false;
        }
    }

    navigateLabelPrint() {
        sessionStorage.setItem('batchCode', this.dailyActivity.get('pt_activitybatchcode').value);
        sessionStorage.setItem('productId', this.dailyActivity.get('pt_Product_id').value);
        sessionStorage.setItem('labelCount', String(this.labelCount));
        this.router.navigate(['final-label-print']);
    }

    onSubmit() {
        this.formValidation()
    }

    formValidation() {
        this.dailyActivityError.pt_processor_id = "";
        this.dailyActivityError.pt_product_id = "";
        this.dailyActivityError.pt_activityname = "";
        this.dailyActivityError.pt_activitydurartion = "";
        this.dailyActivityError.pt_activitydailystarttime = "";
        this.dailyActivityError.pt_activitydailyendtime = "";
        this.dailyActivityError.pt_activitydesc = "";
        this.dailyActivityError.pt_activityshortdesc = "";
        this.dailyActivityError.pt_activitybatchcode = "";
        let hasError = false

        if (this.dailyActivity.get('pt_processor_id').invalid) {
            this.dailyActivityError.pt_processor_id = "Select the Processor";
            hasError = true;
        }
        if (this.dailyActivity.get('pt_Product_id').invalid) {
            this.dailyActivityError.pt_product_id = "Select the Product";
            hasError = true;
        }
        if (this.dailyActivity.get('pt_activityname').invalid) {
            this.dailyActivityError.pt_activityname = "Select the Activity Name";
            hasError = true;
        }
        /*if (this.dailyActivity.get('pt_activitydurartion').invalid) {
            this.dailyActivityError.pt_activitydurartion = "Enter the duration";
            hasError = true;
        }*/
        if (this.dailyActivity.get('pt_activitydailystarttime').invalid) {
            this.dailyActivityError.pt_activitydailystarttime = "Choose the Start Date";
            hasError = true;
        }
        if (this.dailyActivity.get('pt_activitydailyendtime').invalid) {
            this.dailyActivityError.pt_activitydailyendtime = "Choose the End Date";
            hasError = true;
        }
        if (this.dailyActivity.get('pt_activitydesc').invalid) {
            this.dailyActivityError.pt_activitydesc = "Enter the description";
            hasError = true;
        }
        /*if (this.dailyActivity.get('pt_activityshortdesc').invalid) {
            this.dailyActivityError.pt_activityshortdesc = "Enter the short description";
            hasError = true;
        }*/
        if (this.dailyActivity.get('pt_activitybatchcode').invalid) {
            this.dailyActivityError.pt_activitybatchcode = "Enter the Batch Code";
            hasError = true;
        }

        if (!hasError) {
            if (!this.isUpdate) {
                this.saveActivity();
            } else {
                this.updateActivity()
            }
        }
    }

    saveActivity() {
        this.dailyActivity.controls['pt_activitycreatedby'].setValue(this.processorId);
        this.dailyActivity.controls['pt_processor_id'].setValue(this.processorId);
        this.dailyActivity.controls['pt_Product_id'].setValue(this.productId);
        this.dailyActivity.controls['pt_addactivity_id'].setValue(this.activeId)
        this.dailyActivity.controls['pt_activityname'].setValue(this.activeName);
        this.dailyActivity.controls['activeyn'].setValue('Yes');
        this.dailyActivity.controls['deleteyn'].setValue('No');
        console.log(this.dailyActivity.value)
        this.spinner.show();
        this.appService.saveDailyActivity(this.dailyActivity.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.alertShow(data);
                console.log("Save Activity Details :", data)
                this.spinner.hide();
            }, (err: any) => {
                console.log("something went wrong :", err)
                this.spinner.hide();
            })
    }


    updateActivity() {
        this.dailyActivity.controls['pt_activitycreatedby'].setValue(this.processorId);
        this.dailyActivity.controls['pt_addactivity_id'].setValue(this.activeId)
        this.dailyActivity.controls['pt_activity_id'].setValue(this.activityId)
        this.dailyActivity.controls['pt_activityname'].setValue(this.activeName);
        console.log(this.dailyActivity.value)
        this.spinner.show();
        this.appService.updateDailyActivity(this.dailyActivity.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                this.updateShow()
                console.log("Save Activity Details :", data)
            }, (err: any) => {
                this.spinner.hide();
                console.log("something went wrong :", err)
            })
    }

    selectCheckbox(actid: string) {
        if (this.activityId == actid) {
            this.activityId = null
        } else {
            this.activityId = actid
            this.MappingAlert();
        }
    }

    setValueFunction() {
        const cureentValue = this.activityByDate.filter(item => item.pt_activity_id == this.activityId)
        console.log("getvalue::", cureentValue)
        for (let pro of cureentValue) {
            this.dailyActivity.controls['pt_activityname'].setValue(pro.pt_addactivity_id);
            this.activeName = pro.pt_activityname;
            /*const duration = pro.pt_activitydurartion.split('-');
            this.dailyActivity.controls['pt_activitydurartion'].setValue(duration[0]);
            if (duration[1] == 'HR') {
                this.dailyActivity.controls['durationType'].setValue('Hours')
            } else if (duration[1] == 'Min') {
                this.dailyActivity.controls['durationType'].setValue('Minutes')
            }*/
            this.dailyActivity.controls['pt_activitydailystarttime'].setValue(pro.pt_activitydailystarttime);
            this.dailyActivity.controls['pt_activitydailyendtime'].setValue(pro.pt_activitydailyendtime);
            this.dailyActivity.controls['pt_activitydesc'].setValue(pro.pt_activitydesc);
            this.dailyActivity.controls['pt_activityshortdesc'].setValue(pro.pt_activityshortdesc);
            this.dailyActivity.controls['pt_activitycreatedby'].setValue(pro.pt_activitycreatedby);
            this.processorId = pro.pt_activitycreatedby;
            this.dailyActivity.controls['activeyn'].setValue(pro.activeyn);
            this.dailyActivity.controls['deleteyn'].setValue(pro.deleteyn);
            this.dailyActivity.controls['pt_addactivity_id'].setValue(pro.pt_addactivity_id);
            this.activeId = pro.pt_addactivity_id
            this.dailyActivity.controls['pt_activity_secretcode'].setValue(pro.pt_activity_secretcode);
            this.dailyActivity.controls['pt_activitybatchcode'].setValue(pro.pt_activitybatchcode);
            this.dailyActivity.controls['pt_Product_id'].setValue(pro.pt_Product_id);
            this.productId = pro.pt_Product_id;
            this.showActivity = this.activityByProcessor.filter(item => item.pt_activity_id == pro.pt_addactivity_id);
            if (this.showActivity[0].pt_activity_id == this.showActivity[0].pt_activity_next_activity_id) {
                this.finalActivity = true;
            } else {
                this.finalActivity = false;
            }
        }
        this.dailyActivity.controls['pt_activitydailyendtime'].enable();
    }

    startDateValidation(event) {
        const dateValue = event.target.value;
        const batchCode = this.dailyActivity.get('pt_activitybatchcode').value
        const previousActivity = this.activityByDate.find(item => item.pt_addactivity_id == this.showActivity[0].pt_activity_prev_activity_id && item.pt_activitybatchcode == batchCode);
        if (previousActivity != undefined) {
        const previousEndDate = previousActivity.pt_activitydailyendtime.split('T')[0];
        const previousEndTime = previousActivity.pt_activitydailyendtime.split('T')[1];
            if (previousEndDate == dateValue.split('T')[0]) {
                if (previousEndTime > dateValue.split('T')[1]) {
                    this.dailyActivityError.pt_activitydailystarttime = "Start time should be greater than previous activity end time";
                } else {
                    this.dailyActivityError.pt_activitydailystarttime = "";
                    this.dailyActivity.controls['pt_activitydailyendtime'].enable();
                }
            } else if (previousEndDate > dateValue.split('T')[0]) {
                this.dailyActivityError.pt_activitydailystarttime = "Start date should be greater than previous activity end date";
            } else {
                this.dailyActivityError.pt_activitydailystarttime = "";
                this.dailyActivity.controls['pt_activitydailyendtime'].enable();
            }
        } else {
            this.dailyActivity.controls['pt_activitydailyendtime'].enable();
        }
    }

    endDateValidation(event) {
        const dateValue = event.target.value;
        const startDate = this.dailyActivity.get('pt_activitydailystarttime').value.split('T')[0];
        const startTime = this.dailyActivity.get('pt_activitydailystarttime').value.split('T')[1];
        if (startDate == dateValue.split('T')[0]) {
            if (startTime > dateValue.split('T')[1]) {
                this.dailyActivityError.pt_activitydailyendtime = "End time should be greater than start time";
            } else {
                this.dailyActivityError.pt_activitydailyendtime = "";
            }
        } else if (startDate > dateValue.split('T')[0]) {
            this.dailyActivityError.pt_activitydailyendtime = "End date should be greater than start date";
        } else {
            this.dailyActivityError.pt_activitydailyendtime = "";
        }
    }

    alertShow(data: DailyActivityDetails) {
        if (data.pt_addactivity_id != data.pt_activity_id_next) {
            Swal.fire({
                title: "Saved successfully..!",
                text: "Activity details saved successfully. Please add next activity details",
                icon: "success",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.showActivity = [];
                    this.spinner.show();
                    this.dailyActivity.controls['pt_activitydailystarttime'].reset();
                    this.dailyActivity.controls['pt_activitydailyendtime'].reset();
                    this.dailyActivity.controls['pt_activitydesc'].reset();
                    this.dailyActivity.controls['pt_activityname'].setValue('');
                    this.showActivity = this.activityByProcessor.filter(item => item.pt_activity_id == data.pt_activity_id_next);
                    console.log(this.showActivity)
                    this.activeId = this.showActivity[0].pt_activity_id;
                    this.activeName = this.showActivity[0].pt_activityname;
                    this.dailyActivity.controls['pt_activityname'].setValue(this.showActivity[0].pt_activity_id);
                    this.dailyActivity.controls['pt_activity_id_previous'].setValue(this.showActivity[0].pt_activity_prev_activity_id);
                    this.dailyActivity.controls['pt_activity_id_next'].setValue(this.showActivity[0].pt_activity_next_activity_id);
                    this.spinner.hide();
                }
            });
        } else {
            Swal.fire({
                title: "Saved successfully..!",
                text: "Activity details saved successfully. Please enter the label count and generate",
                icon: "success",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.spinner.show();
                    this.dailyActivity.disable();
                    this.labelCountInput = true;
                    this.spinner.hide();
                }
            });
        }
        this.dateBasedData('');
    }

    updateShow() {
        this.activityId = null
        this.isUpdate = false;
        if (!this.finalActivity) {
            Swal.fire({
                title: "Updated successfully..!",
                text: "Activity details updated successfully.",
                icon: "success",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.spinner.show();
                    this.dailyActivity.controls['pt_activitydailystarttime'].reset();
                    this.dailyActivity.controls['pt_activitydailyendtime'].reset();
                    this.dailyActivity.controls['pt_activitydesc'].reset();
                    this.dailyActivity.controls['pt_activityname'].setValue('');
                    this.dailyActivity.controls['pt_activitybatchcode'].reset();
                    this.spinner.hide();
                }
            });
        } else {
            Swal.fire({
                title: "Updated successfully..!",
                text: "Activity details updated successfully. Please enter the label count and generate",
                icon: "success",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.spinner.show();
                    this.dailyActivity.disable();
                    this.labelCountInput = true;
                    this.spinner.hide();
                }
            });
        }
        this.dateBasedData('');
    }


    MappingAlert() {
        Swal.fire({
            title: "Would you like to make any changes to the activity details?",
            showDenyButton: true,
            confirmButtonColor: "#4fab69",
            denyButtonColor: "#c73636",
            confirmButtonText: "Yes",
            denyButtonText: "No",
            showClass: {
                popup: "animate_animated animate_fadeInDown",
            },
            hideClass: {
                popup: "animate_animated animate_fadeOutUp",
            },
            backdrop: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.setValueFunction();
                this.isUpdate = true;
            }
        });
    }
}
